<!--  -->
<template>
  <div class=''>
    <div style="font-size: 35px">远程诊疗出诊历史</div>
    <el-divider></el-divider>
    <el-input
        style="width: 30%"
        placeholder="请输入关键词查询"
        v-model="search"
        clearable>
    </el-input>
<!--    诊疗记录，显示数据包括病人姓名（用户名），症状备注（纯文本，使用按钮打开弹出框显示），诊疗时间（暂定诊疗日期【年-月-日】-开始时间），诊疗笔记（诊疗过程中提交，纯文本，使用按钮打开弹出框显示）-->
<!--    此外还有诊断结论和医嘱备注两个可输入框，考虑和以上数据一起存储，方便用户的就诊历史界面读取-->
<!--    <el-table :data="treatmentData.filter(data => !search || data.treatmentUser.toLowerCase().includes(search.toLowerCase()))"
              style="width: 100%">
      <el-table-column label="用户" prop="treatmentUser"></el-table-column>

      <el-table-column label="症状备注" prop="appointmentRemark">
        <template v-slot="scope1">
          <el-popover
              title="症状备注"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope1.row.appointmentRemark}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="诊疗时间" prop="treatmentTime"></el-table-column>

      <el-table-column label="诊疗笔记" prop="treatmentNote">
        <template v-slot="scope2">
          <el-popover
              title="诊疗笔记"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope2.row.treatmentNote}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="诊断结论" prop="treatmentResult">
        <template v-slot="scope3">
          <el-popover
            title="诊断结论"
            placement="right"
            trigger="click"
            width="auto">
            <el-form model="resultForm">
              <el-form-item label="诊断结论">
                <el-input type="textarea" v-model="scope3.row.resultForm.treatmentResult"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit1">提交诊断结果</el-button>
              </el-form-item>
            </el-form>
            <el-button slot="reference" type="primary">填写结论</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="备注" prop="treatmentRemark">
        <template v-slot="scope4">
          <el-popover
              title="医嘱备注"
              placement="right"
              trigger="click"
              width="auto">
            <el-form model="remarkForm">
              <el-form-item label="医嘱备注">
                <el-input type="textarea" v-model="scope4.row.remarkForm.treatmentRemark"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit1">提交医嘱备注</el-button>
              </el-form-item>
            </el-form>
            <el-button slot="reference" type="primary">填写医嘱备注</el-button>
          </el-popover>
        </template>
      </el-table-column>

    </el-table>

    <el-divider></el-divider>
    <div>{{appointHistoryString}}</div>
    <div>{{typeof appointHistoryString}}</div>
    <el-divider></el-divider>-->

    <el-table :data="appointHistoryString.filter(data => !search || data.uname.toLowerCase().includes(search.toLowerCase()))"
    :default-sort = "{prop: 'diagnosisTime', order: 'descending'}"
    >
      <el-table-column label="用户" prop="uname" sortable></el-table-column>

      <el-table-column label="症状备注" prop="symptoms">
        <template v-slot="scope11">
          <el-popover
              title="症状备注"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope11.row.symptoms}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="诊疗时间" prop="diagnosisTime" sortable></el-table-column>

      <el-table-column label="治疗笔记" prop="diagnosisNotes">
        <template v-slot="scope12">
          <el-popover
              title="诊疗笔记"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope12.row.diagnosisNotes}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="诊断结论" prop="diagnosisConclusion">
        <template v-slot="scope13">
          <el-popover
              title="诊断结论"
              placement="right"
              trigger="click"
              width="auto">
            <el-form>
              <el-form-item label="诊断结论">
                <el-input type="textarea" v-model="scope13.row.diagnosisConclusion"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="conclusionSubmit(scope13.row)">提交诊断结果</el-button>
              </el-form-item>
            </el-form>
            <el-button slot="reference" type="primary">填写结论</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="医嘱备注" prop="diagnosisAdvise">
        <template v-slot="scope14">
          <el-popover
              title="医嘱备注"
              placement="right"
              trigger="click"
              width="auto">
            <el-form>
              <el-form-item label="诊断结论">
                <el-input type="textarea" v-model="scope14.row.diagnosisAdvise"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="adviseSubmit(scope14.row)">提交医嘱备注</el-button>
              </el-form-item>
            </el-form>
            <el-button slot="reference" type="primary">填写医嘱</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="诊疗报告" prop="diagnosisConclusion">
        <template v-slot="scope13">
          <el-button type="primary"  @click="goReport(scope13.row)">查看报告</el-button>
        </template>
      </el-table-column>

    </el-table>

  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
//这里存放数据
    return {
      search:'',
      appointHistoryString: [],
    };
  },
//监听属性 类似于data概念
  computed: {},
//监控data中的数据变化
  watch: {},
//方法集合
  methods: {
    onSubmit1() {
      console.log('submit!');
    },

    HistoryGet(){
      this.$http({
        url: this.$http.adornUrl('/doctor/recorddiagnosis/listById'),
        method: 'get',
        params: this.$http.adornParams({ docId: this.$store.state.doctor_station.docid})
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看收到的数据")
          console.log(data)
          this.appointHistoryString = data.recordDiagnosisEntity
        } else {
          this.$message.error(data.msg)
        }
      })
    },

    conclusionSubmit(Obj){
      this.$http({
        url: this.$http.adornUrl('/doctor/recorddiagnosis/saveConclusion'),
        method: 'post',
        data: this.$http.adornData({
          diagnosisId: Obj.diagnosisId,
          diagnosisConclusion: Obj.diagnosisConclusion,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看提交后返回的东西")
          console.log(data)
          this.$notify({
            title: '成功',
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message.error(data.msg)
        }
      })
    },

    adviseSubmit(Obj){
      this.$http({
        url: this.$http.adornUrl('/doctor/recorddiagnosis/saveAdvise'),
        method: 'post',
        data: this.$http.adornData({
          diagnosisId: Obj.diagnosisId,
          diagnosisAdvise: Obj.diagnosisAdvise,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看提交后返回的东西")
          console.log(data)
          this.$notify({
            title: '成功',
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message.error(data.msg)
        }
      })
    },

    goReport(Obj){
      this.$router.push({
        name: 'TreatReport',
        query: {
          diagnosisId: Obj.diagnosisId,
        }
      })
    },
  },
//生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.HistoryGet();
  },
//生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>

</style>
